import { render, staticRenderFns } from "./SelectGoodsUp.vue?vue&type=template&id=71ff1e6d&scoped=true"
import script from "./SelectGoodsUp.vue?vue&type=script&lang=js"
export * from "./SelectGoodsUp.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71ff1e6d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/www/jenkins_workspase/workspace/html-sunyun-saas-erp-application-pre/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('71ff1e6d')) {
      api.createRecord('71ff1e6d', component.options)
    } else {
      api.reload('71ff1e6d', component.options)
    }
    module.hot.accept("./SelectGoodsUp.vue?vue&type=template&id=71ff1e6d&scoped=true", function () {
      api.rerender('71ff1e6d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/O2OMall/manage/components/SelectGoodsUp.vue"
export default component.exports