<template>
    <!--    门店自提-->
    <div v-if="load">
        <el-form :model="form">
            <el-form-item label="是否启用">
                <el-switch
                    v-model="form.isEnable">
                </el-switch>
            </el-form-item>
            <div v-if="form.isEnable">
                <el-form-item>
                    <el-transfer
                        :titles="['未选门店', '已选门店']"
                        @change="transferChange"
                        v-model="form.shopIds"
                        :data="shopList"
                    ></el-transfer>
                </el-form-item>
                <el-form-item label="提货时间：">
                    <el-switch
                        v-model="form.enableSelfDeliveryTime"
                        active-color="#188ffe"
                        inactive-color="#919396"
                    >
                    </el-switch>
                    <span class="marL15 fontColor" style="font-size: 12px;"
                    >关闭后，买家不需要选择到店自提时间，可根据买家时间随时到店提货</span>
                </el-form-item>
                <div v-if="form.enableSelfDeliveryTime">
                    <el-form-item label-width="50px">
                        <span style="color: red">*</span>
                        <span class="fontColor">可选择日期：开始日期为下单</span>
                        <el-input size="mini" v-model="form.beginOrderDay" class="inputW100 marL15 marR15" placeholder="0"
                        ></el-input>
                        <span class="fontColor">天后至</span>
                        <el-input size="mini" v-model="form.endOrderDay" class="inputW100 marL15 marR15" placeholder="7"
                        ></el-input>
                        <span class="fontColor">天内结束</span>
                    </el-form-item>
                    <el-form-item label-width="50px">
                        <span style="color: red">*</span>
                        <span class="fontColor">可选择时间：</span>
                        <el-checkbox v-model="form.enableSelectTime">勾选后买家先需要选择具体的时间</el-checkbox>
                        <div class="y-start" v-if="form.enableSelectTime">
                            <div style="margin-left: 85px;" class="y-start">
                                <div class="x-f timeChoose">
                                    <el-radio class="radioWidth" v-model="form.timeChoose" :label="3">全天</el-radio>
                                    <div class="x-f" v-if="form.timeChoose == 3">
                                        下单
                                        <el-input size="mini" v-model="form.afterOrderHour" class="inputW100 marL15 marR15 inputH"
                                                  placeholder="1"
                                        ></el-input>
                                        <span class="fontColor">小时后</span>
                                    </div>
                                </div>
                                <div class="x-f timeChoose">
                                    <el-radio class="radioWidth" v-model="form.timeChoose" :label="2">营业时间段</el-radio>
                                    <div class="x-f" v-if="form.timeChoose == 2">
                                        下单
                                        <el-input size="mini" v-model="form.afterOrderHour" class="inputW100 marL15 marR15 inputH"
                                                  placeholder="1"
                                        ></el-input>
                                        <span class="fontColor">小时后</span>
                                    </div>
                                </div>
                                <div class="x-f timeChoose">
                                    <el-radio class="radioWidth" v-model="form.timeChoose" :label="1">自定义时间段
                                    </el-radio>
                                    <div class="x-f" v-if="form.timeChoose == 1">
                                        下单
                                        <el-input size="mini" v-model="form.afterOrderHour" class="inputW100 marL15 marR15 inputH"
                                                  placeholder="1"
                                        ></el-input>
                                        <span class="fontColor">小时后</span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="form.timeChoose == 1">
                                <el-form-item label="时间段：" label-width="100px" class="marT10">
                                    <div class="box">
                                        <el-table
                                            :data="timeSlots"
                                            border
                                            style="width: 600px"
                                        >
                                            <el-table-column type="index" label="序号" align="center" width="55"
                                            ></el-table-column>
                                            <el-table-column label="操作" align="center" width="150">
                                                <template slot-scope="scope">
                                                    <i
                                                        @click="row('push', scope.$index)"
                                                        class="el-icon-circle-plus operatePush"
                                                    ></i>
                                                    <i
                                                        @click="row('del', scope.$index)"
                                                        class="el-icon-remove operateDel"
                                                    ></i>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" prop="address" label="时间段">
                                                <template slot-scope="scope">
                                                    <el-time-picker is-range v-model="scope.row.times" @change="timepicker(scope.$index, scope.row.times)"
                                                                    range-separator="至" value-format="HH:mm:ss"
                                                                    start-placeholder="开始时间" size="mini"
                                                                    end-placeholder="结束时间" placeholder="选择时间范围"
                                                    >
                                                    </el-time-picker>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </el-form-item>
                            </div>
                        </div>
                    </el-form-item>
                </div>
                <el-form-item class="x-start">
                    <el-button type="primary" size="mini" @click="submit">保存</el-button>
<!--                    <c-button test="保存" :bgColor="'#188ffe'" @click="submit"></c-button>-->
                </el-form-item>
            </div>
        </el-form>
    </div>
</template>

<script>
import { deliverySroteSave, pickUpInAstoreDetail, deliverySroteUpdate } from '@/api/O2OMall/manage/delivery'
import CButton from '@/views/components/c-button/index.vue'

export default {
    name: 'pickUpInAstore', // 门店自提
    components: {
        CButton
    },
    props: {
        shopList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            load: false,
            transfer: [],
            form: {
                isEnable: false,
                deliveryGetInShopId: '',
                enableSelfDeliveryTime: false,
                beginOrderDay: '', // 可选择日期(开始时间：单位：天)
                endOrderDay: '', // 可选择日期(结束时间：单位：天)
                enableSelectTime: '', // 是否启用可选择时间(勾选后买家先需要选择具体的时间)
                afterOrderHour: '', // 下单多少小时之后
                timeChoose: '', // 时间段选择(1：是否是自定义时间段; 2: 是否是营业时间段; 3: 是否全天)
                shopIds: [],
                timeSlots: []
            },
            timeSlots: [{}]
        }
    },
    watch:{
       'form.isEnable':{
           handler(newName, oldName){
               if(!newName){
                   console.log('关闭', newName)
                   this.submit()
               }
           }

       }
    },
    created() {
        this.getDetail()
    },
    methods: {
        getDetail() {
            pickUpInAstoreDetail().then(res => {
                console.log('门店字体详情：', res)
                let data = res.data
                if(data == null) return
                this.form = {
                    isEnable: data.isEnable,
                    deliveryGetInShopId: data.deliveryGetInShopId || '', // 主键ID
                    enableSelfDeliveryTime: data.enableSelfDeliveryTime || false,
                    beginOrderDay: data.beginOrderDay ||'', // 可选择日期(开始时间：单位：天)
                    endOrderDay: data.endOrderDay ||'', // 可选择日期(结束时间：单位：天)
                    enableSelectTime: data.enableSelectTime, // 是否启用可选择时间(勾选后买家先需要选择具体的时间)
                    afterOrderHour: data.afterOrderHour || '', // 下单多少小时之后
                    timeChoose: data.timeChoose || '', // 时间段选择(1：是否是自定义时间段; 2: 是否是营业时间段; 3: 是否全天)
                    shopIds: data.shopIds,
                    customTime: data.customTime, // 自定义时间段
                }
                this.timeSlots = data.timeSlots.length < 1 ? [{}] : data.timeSlots
            }).finally(()=>{
                this.load = true
            })
        },
        // 穿梭框数据发生改变事件
        transferChange(val) {
            console.log('穿梭框：', val)
            console.log('穿梭框2：', this.form.shopIds)
            // if (val.length > 0) {
            //     this.form.shopIds = val
            // }
        },
        submit() {
            this.form.timeSlots = this.timeSlots
            console.log('提交：',this.form)
            if(!this.form.deliveryGetInShopId){ // 添加
                deliverySroteSave(this.form).then(res => {
                    this.$message.success('添加成功')
                })
            } else { // 编辑
                deliverySroteUpdate(this.form).then(res => {
                    this.$message.success('修改成功')
                })
            }

        },
        // 时间段列表时间选择
        timepicker(index, row) {
            console.log('时间段：', index)
            console.log('时间段2：', this.timeSlots)
            console.log('时间段3：', row)
            // this.timeSlots[index].timeSlot = row
            // this.form.timeSlots
        },
        row(type, index) {
            if (type == 'push') {
                this.timeSlots.push({})
            } else {
                if (this.timeSlots.length <= 1) return
                this.timeSlots.splice(index, 1)
            }
        },


    }
}
</script>

<style scoped>
.inputW100 {
    width: 100px;
}

.radioWidth {
    width: 150px;
}

.timeChoose {
    height: 30px;
}
</style>
