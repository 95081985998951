<template>
  <el-form :model="form">
    <el-form-item label="是否启用">
      <el-switch v-model="form.isEnable"></el-switch>
    </el-form-item>

    <div v-if="form.isEnable">
      <el-form-item>
        <el-transfer :titles="['未选门店', '已选门店']" @change="transferChange" v-model="form.shopIds"
          :data="shopList"></el-transfer>
      </el-form-item>
      <el-form-item label="微信快递">
        <el-table style="width:830px" ref="multipleTable" :data="uuData" border max-height="320" height="300"
          v-loading="loadingTable" @cell-mouse-enter="cellMouseEnter" @cell-mouse-leave="cellMouseLeave" row-key>
          <el-table-column align="center" :label="tableCellLabel" width="80">
            <template v-slot="scope">
              <i v-if="scope.row.hoverRow" @click.stop="rowChange('push', scope.$index)"
                class="el-icon-circle-plus operatePush"></i>
              <i v-if="scope.row.hoverRow" @click.stop="rowChange('del', scope.$index)"
                class="el-icon-remove operateDel"></i>
              <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
            </template>
          </el-table-column>
          <el-table-column label="是否绑定" align="center" prop="type" min-width="100">
            <template slot-scope="scope">
              <el-form-item label-width="0">
                <el-switch v-model="scope.row.type" size="mini" active-value="bind" inactive-value="unbind" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="bizId" align="center" prop="bizId" min-width="120">
            <template slot-scope="scope">
              <el-form-item label-width="0">
                <el-input v-model="scope.row.bizId" size="mini" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="快递公司" align="center" prop="deliveryId" min-width="120" key="6">
            <template slot-scope="scope">
              <el-form-item label-width="0">
                <el-select clearable v-model="scope.row.deliveryId" placeholder="请选择" filterable size="mini">
                  <el-option v-for=" item in uuShopData" :key="item.deliveryId" :value="item.deliveryId"
                    :label="item.deliveryName" />
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="快递公司客户密码" align="center" prop="password" min-width="140" key="7">
            <template slot-scope="scope">
              <el-form-item label-width="0">
                <el-input v-model="scope.row.password" size="mini" />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="备注内容" align="center" prop="remarkContent" min-width="260" key="8">
            <template slot-scope="scope">
              <el-form-item label-width="0">
                <el-input placeholder="格式要求：电话：xxxxx 联系人：xxxxx 服务类型：xxxxx 发货地址：xxxx\n" v-model="scope.row.remarkContent"
                  size="mini" />
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="">
        <span>自定义显示内容：</span>
        <el-input size="mini" v-model="form.customContent" class="inputWidth" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="起送金额：">
        <span>订单满</span>
        <el-input-number size="mini" style="margin: 0 8px" v-model="form.startingAmount" :precision="2" :step="0.01"
          :min="0" :controls="false" />
        <span>元起送，以实付金额为准</span>
      </el-form-item>
      <el-form-item label="">
        <div class="x-start marT10" style="align-items: center">
          <span>计费规则：</span>
          <el-radio v-model="form.calcChargeRule" :label="1"> 按商品累加运费 </el-radio>
          <el-radio v-model="form.calcChargeRule" :label="2"> 按组合运费 </el-radio>
        </div>
      </el-form-item>

      <el-form-item class="x-start">
        <el-button type="primary" size="mini" @click="submit">保存</el-button>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import {
  getCellMouseEnter, //显示加减
  getCellMouseLeave, //隐藏加减
} from "@/utils/orderTableSelect.js";
import {
  expressageDistriDetail,
  expressageDistriSave,
  expressageDistriUpdate,
  wxkdGetAllDelivery,
  wxkdAccount,
  wxkdGetAllAccount,
} from "@/api/O2OMall/manage/delivery";
import CButton from "@/views/components/c-button/index.vue";

export default {
  name: "expressageDistribution", // 快递配送
  props: {
    shopList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    CButton,
  },
  data() {
    return {
      tableCellLabel: "序号",
      uuShopData: [],
      uuData: [{}],
      loadingTable: false,
      transfer: [],
      form: {
        isEnable: false,
        deliveryExpressId: "",
        customContent: "",
        isEnableDeliveryTime: false,
        calcChargeRule: 1,
        shopIds: [],
      },

      switchVal: "",
    };
  },
  watch: {
    "form.isEnable": {
      handler(newName, oldName) {
        if (!newName) {
          console.log("关闭", newName);
          this.submit();
        }
      },
    },
  },
  async created() {
    this.getexpressageDistriDetail();
    try {
      this.loadingTable = true
      let res1 = await wxkdGetAllAccount()
      if (!res1.data.list || res1.data.list.length == 0) {
        this.uuData = [{}]
      } else {
        this.uuData = res1.data?.list || []
      }
      let res2 = await wxkdGetAllDelivery()
      this.uuShopData = res2?.data?.data || []
    } finally {
      this.loadingTable = false
    }
  },
  methods: {
    rowChange(name, index) {
      if (name === "push") {
        this.uuData.splice(index + 1, 0, {
          hoverRow: false,
        });
      } else {
        if (this.uuData.length <= 1) {
          this.uuData = [{
            hoverRow: false,
          }]
        } else {
          this.uuData.splice(index, 1);
        }
      }
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      this.uuData = getCellMouseEnter(
        row,
        this.uuData
      );
      this.tableCellLabel = "操作";
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      this.uuData = getCellMouseLeave(this.uuData);
      this.tableCellLabel = "序号";
    },
    getexpressageDistriDetail() {
      expressageDistriDetail().then((res) => {
        console.log("快递配送：", res);
        let data = res.data;
        this.form = {
          isEnable: data.isEnable,
          deliveryExpressId: data.deliveryExpressId,
          customContent: data.customContent,
          isEnableDeliveryTime: data.isEnableDeliveryTime,
          startingAmount: data.startingAmount,
          calcChargeRule: data.calcChargeRule,
          shopIds: data.shopIds,
        };
      });
    },
    // 穿梭框数据发生改变事件
    transferChange(val) {
      console.log("穿梭框：", val);
      if (val.length > 0) {
        this.form.shopIds = val;
      }
    },
    async submit() {
      // this.form.timeSlots = this.timeSlots
      if (!this.form.deliveryExpressId) {
        expressageDistriSave(this.form).then((res) => {
          this.$message.success("添加成功");
        });
      } else {
        expressageDistriUpdate(this.form).then((res) => {
          this.$message.success("编辑成功");
        });
      }
      await wxkdAccount(this.uuData)
    },
  },
};
</script>

<style scoped>
.inputW100 {
  width: 100px;
}
</style>
