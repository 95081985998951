<template>
    <el-form method="form">
        <el-form-item label="自提时间：">
            <el-switch
                v-model="switchVal"
                active-color="#188ffe"
                inactive-color="#919396"
            >
            </el-switch>
            <span class="marL15 fontColor" style="font-size: 12px;"
            >关闭后，买家不需要选择到店自提时间，可根据买家时间随时到店自提</span>
        </el-form-item>
        <el-form-item label-width="50px">
            <span style="color: red">*</span>
            <span class="fontColor">可选择日期：开始日期为下单</span>
            <el-input v-model="form.value" class="inputW100 marL15 marR15" placeholder="0"></el-input>
            <span class="fontColor">天后至</span>
            <el-input v-model="form.value" class="inputW100 marL15 marR15" placeholder="7"></el-input>
            <span class="fontColor">天内结束</span>
        </el-form-item>
        <el-form-item label-width="50px">
            <span class="fontColor">可选择时间：</span>
            <el-checkbox v-model="checked">勾选后买家先需要选择具体的时间</el-checkbox>
            <div class="x-start" v-if="!checked">
                <div style="margin-left: 85px;" class="y-start">
                    <div class="x-f">
                        <el-radio class="radioWidth" v-model="radio" label="1">全天</el-radio>
                        <div class="x-f" v-if="radio == 1">
                            <el-checkbox v-model="checked1_2">下单</el-checkbox>
                            <el-input v-model="form.value" class="inputW100 marL15 marR15 inputH" placeholder="1"
                            ></el-input>
                            <span class="fontColor">小时候</span>
                        </div>
                    </div>
                    <div class="x-f">
                        <el-radio class="radioWidth" v-model="radio" label="2">营业时间段</el-radio>
                        <div class="x-f" v-if="radio == 2">
                            <el-checkbox v-model="checked1_2">下单</el-checkbox>
                            <el-input v-model="form.value" class="inputW100 marL15 marR15 inputH" placeholder="1"
                            ></el-input>
                            <span class="fontColor">小时候</span>
                        </div>
                    </div>
                    <div class="x-f">
                        <el-radio class="radioWidth" v-model="radio" label="3">自定义时间段</el-radio>
                        <div class="x-f" v-if="radio == 3">
                            <el-checkbox v-model="checked1_2">下单</el-checkbox>
                            <el-input v-model="form.value" class="inputW100 marL15 marR15 inputH" placeholder="1"
                            ></el-input>
                            <span class="fontColor">小时候</span>
                        </div>
                    </div>
                </div>
            </div>
        </el-form-item>
        <el-form-item label="时间段：" label-width="200px">
            <el-input v-model="form.times" class="inputWidth" placeholder="选择时间段">
                <i slot="suffix" class="el-input__icon el-icon-time "></i>
            </el-input>
            <el-button type="warning" class="marL15" @click="showBox = true">添加</el-button>
            <div class="box">
                <div v-if="showBox" class="box-dialog">
                    <div class="x-start tabTit">
                        <div v-for="(item,index) in tabTit" :key="index"
                        >
                            <div class="tit-text">{{ item }}</div>
                        </div>
                    </div>
                    <div class="y-start">
                        <div class="x-start box-tab" v-for="(item, index) in tabMsg" :key="index"
                             @click="selectRow(item)"
                        >
                            <div class="tab-text">{{ item }}</div>
                            <div style="width: 200px;">
                                <el-button>删除</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-form-item>

        <!--        ------------------------------------------->
        <!--        <el-form-item label-width="50px">-->
        <!--            <span class="fontColor">可选择时间：</span>-->
        <!--            <el-checkbox v-model="checked">勾选后买家先需要选择具体的时间</el-checkbox>-->
        <!--            <div class="x-start" v-if="!checked">-->
        <!--                <div style="margin-left: 85px;" class="y-start">-->
        <!--                    <div class="x-f">-->
        <!--                        <el-radio class="radioWidth" v-model="radio" label="1">全天</el-radio>-->
        <!--                        <div class="x-f" v-if="radio == 1">-->
        <!--                            <el-checkbox v-model="checked1_2">下单</el-checkbox>-->
        <!--                            <el-input v-model="form.value" class="inputW100 marL15 marR15 inputH" placeholder="1"-->
        <!--                            ></el-input>-->
        <!--                            <span class="fontColor">小时候</span>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <div class="x-f">-->
        <!--                        <el-radio class="radioWidth" v-model="radio" label="2">营业时间段</el-radio>-->
        <!--                        <div class="x-f" v-if="radio == 2">-->
        <!--                            <el-checkbox v-model="checked1_2">下单</el-checkbox>-->
        <!--                            <el-input v-model="form.value" class="inputW100 marL15 marR15 inputH" placeholder="1"-->
        <!--                            ></el-input>-->
        <!--                            <span class="fontColor">小时候</span>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <div class="x-f">-->
        <!--                        <el-radio class="radioWidth" v-model="radio" label="3">自定义时间段</el-radio>-->
        <!--                        <div class="x-f" v-if="radio == 3">-->
        <!--                            <el-checkbox v-model="checked1_2">下单</el-checkbox>-->
        <!--                            <el-input v-model="form.value" class="inputW100 marL15 marR15 inputH" placeholder="1"-->
        <!--                            ></el-input>-->
        <!--                            <span class="fontColor">小时候</span>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label-width="50px">-->
        <!--            <span class="fontColor">可选择时间：</span>-->
        <!--            <el-checkbox v-model="checked">勾选后买家先需要选择具体的时间</el-checkbox>-->
        <!--            <div class="x-start" v-if="!checked">-->
        <!--                <div style="margin-left: 85px;" class="y-start">-->
        <!--                    <div class="x-f">-->
        <!--                        <el-radio class="radioWidth" v-model="radio" label="1">全天</el-radio>-->
        <!--                        <div class="x-f" v-if="radio == 1">-->
        <!--                            <el-checkbox v-model="checked1_2">下单</el-checkbox>-->
        <!--                            <el-input v-model="form.value" class="inputW100 marL15 marR15 inputH" placeholder="1"-->
        <!--                            ></el-input>-->
        <!--                            <span class="fontColor">小时候</span>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <div class="x-f">-->
        <!--                        <el-radio class="radioWidth" v-model="radio" label="2">营业时间段</el-radio>-->
        <!--                        <div class="x-f" v-if="radio == 2">-->
        <!--                            <el-checkbox v-model="checked1_2">下单</el-checkbox>-->
        <!--                            <el-input v-model="form.value" class="inputW100 marL15 marR15 inputH" placeholder="1"-->
        <!--                            ></el-input>-->
        <!--                            <span class="fontColor">小时候</span>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <div class="x-f">-->
        <!--                        <el-radio class="radioWidth" v-model="radio" label="3">自定义时间段</el-radio>-->
        <!--                        <div class="x-f" v-if="radio == 3">-->
        <!--                            <el-checkbox v-model="checked1_2">下单</el-checkbox>-->
        <!--                            <el-input v-model="form.value" class="inputW100 marL15 marR15 inputH" placeholder="1"-->
        <!--                            ></el-input>-->
        <!--                            <span class="fontColor">小时候</span>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label-width="50px">-->
        <!--            <span class="fontColor">可选择时间：</span>-->
        <!--            <el-checkbox v-model="checked">勾选后买家先需要选择具体的时间</el-checkbox>-->
        <!--            <div class="x-start" v-if="!checked">-->
        <!--                <div style="margin-left: 85px;" class="y-start">-->
        <!--                    <div class="x-f">-->
        <!--                        <el-radio class="radioWidth" v-model="radio" label="1">全天</el-radio>-->
        <!--                        <div class="x-f" v-if="radio == 1">-->
        <!--                            <el-checkbox v-model="checked1_2">下单</el-checkbox>-->
        <!--                            <el-input v-model="form.value" class="inputW100 marL15 marR15 inputH" placeholder="1"-->
        <!--                            ></el-input>-->
        <!--                            <span class="fontColor">小时候</span>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <div class="x-f">-->
        <!--                        <el-radio class="radioWidth" v-model="radio" label="2">营业时间段</el-radio>-->
        <!--                        <div class="x-f" v-if="radio == 2">-->
        <!--                            <el-checkbox v-model="checked1_2">下单</el-checkbox>-->
        <!--                            <el-input v-model="form.value" class="inputW100 marL15 marR15 inputH" placeholder="1"-->
        <!--                            ></el-input>-->
        <!--                            <span class="fontColor">小时候</span>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <div class="x-f">-->
        <!--                        <el-radio class="radioWidth" v-model="radio" label="3">自定义时间段</el-radio>-->
        <!--                        <div class="x-f" v-if="radio == 3">-->
        <!--                            <el-checkbox v-model="checked1_2">下单</el-checkbox>-->
        <!--                            <el-input v-model="form.value" class="inputW100 marL15 marR15 inputH" placeholder="1"-->
        <!--                            ></el-input>-->
        <!--                            <span class="fontColor">小时候</span>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </el-form-item>-->
    </el-form>
</template>

<script>
export default {
    name: 'shop',
    data() {
        return {
            switchVal: '',
            form: {
                times: '',
                value: ''
            },
            checked: false,
            radio: '1',

            checked1_2: false,

            tabTit: ['时间段', '操作'],
            tabMsg: [
                '00:00-02:00',
                '02:00-04:00',
                '04:00-06:00'
            ],
            showBox: false,
            showBox1: false

        }
    },
    methods: {
        selectRow(item) {
            console.log('选择时间段：', item)
            this.showBox = false
            this.form.times = item
        }
    }
}
</script>

<style lang="scss" scoped>
.inputW100 {
    width: 100px;
}

.inputH {
    .el-input__inner {
        height: 25px;
        line-height: 0;
    }
}

.fontColor {
    color: #6e6e6e;
}

.radioWidth {
    width: 120px;
}

.box {
    .box-dialog {
        background: #FFFFFF;
        border: 1px solid #cccccc;
        position: fixed;
        z-index: 999;

        .tabTit {
            background: #f7f7f8;
            padding: 5px 10px;

            .titText {
                width: 200px;
                font-weight: 550;
                height: 35px;
            }
        }

        .box-tab {
            padding: 5px 10px;
            border-bottom: 1px solid #f7f7f7;

            .tab-text {
                width: 200px;
                height: 35px;
            }
        }
    }

}
</style>
