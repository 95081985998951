<template>
  <div>
    <el-dialog
      title="区域选择"
      :visible.sync="isRegion"
      width="800px"
      :before-close="handleClose"
    >
      <div class="x-w" style="font-size: 20px; padding: 20px">
        <div
          class="x-start marB10"
          style="width: 240px"
          v-for="(province, index) in provinceList"
          :key="index"
        >
          <div class="marL10">
            <el-checkbox
              class=""
              size="medium"
              v-model="province.checked"
              @change="provinceChange(province.id, $event)"
            >
              {{ province.label }}
            </el-checkbox>
            <i
              class="el-icon-arrow-down el-icon--right"
              @click="provinceclick(index)"
              @mouseenter="shengEnter(index)"
            ></i>
            <div
              class="cityBox y-start marL10"
              v-if="index == provinceIndex"
              @mouseleave="cityMouseleave"
            >
              <div
                class="cityItem marB10 marL10"
                v-for="(city, cityInx) in province.children"
                :key="cityInx"
              >
                <div class="x-start">
                  <el-checkbox
                    class="marR10"
                    v-model="city.checked"
                    @change="cityChange(province.id, city.id, $event)"
                  >
                    {{ city.label }}
                  </el-checkbox>
                  <i
                    class="el-icon-arrow-down el-icon--right"
                    @click.stop="cityClick(cityInx)"
                    @mouseenter="shiEnter(cityInx)"
                    @mouseleave="shiLeave"
                  ></i>
                </div>
                <div
                  class="areaBox"
                  @mouseleave="areaMouseleave"
                  v-if="cityInx == cityIndex"
                >
                  <div v-for="(area, areaIndex) in city.children" :key="areaIndex">
                    <el-checkbox
                      class="marB10"
                      v-model="area.checked"
                      @change="areaChange(province.id, city.id, area.id, $event)"
                    >
                      {{ area.label }}
                    </el-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="diaOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "districtSelector",
  props: {
    provinceList: {
      type: Array,
      default: () => [],
    },
    isRegion: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: [], // 提交的数据
      areaCheckList: [],

      provinceIndex: null, // 省ID
      cityIndex: null, // 市ID
      areaIndex: null, // 区ID
      obj: {
        areaId: 0, // 省
        cityId: 0, // 市
        provinceId: 0, // 区
      },
    };
  },
  methods: {
    // 关闭弹框
    handleClose() {
      this.$emit("callBack", { type: "close" });
    },
    // 弹框确定
    diaOk() {
      this.$emit("callBack", { type: "submit", data: this.data });
    },
    // 省被点击
    provinceclick(index) {
      console.log("省ID：", index);
      this.provinceIndex = index;
    },
    // 市被点击
    cityClick(index) {
      console.log("市ID：", index);
      this.cityIndex = index;
    },
    // 区被点击
    areaClick(pid, id, myid, statue) {
      console.log("区：", index, statue);
      this.areaIndex = index;
    },
    // 省选择框状态
    provinceChange(id, statue) {
      console.log("省选择框点击：", id, statue);
      let obj = { provinceId: null, cityId: null, areaId: null };
      let pL = this.provinceList.length;
      if (statue) {
        // true 添加
        for (let i = 0; i < pL; i++) {
          if (this.provinceList[i].id == id) {
            if (!this.provinceList[i].children) {
            }
            obj.provinceId = this.provinceList[i].id;
            this.provinceList[i].checked = statue;
            let cL = this.provinceList[i].children.length;
            for (let j = 0; j < cL; j++) {
              console.log("市----:", this.provinceList[i].children[j].id);
              obj.cityId = this.provinceList[i].children[j].id;
              this.provinceList[i].children[j].checked = statue;
              let aL = this.provinceList[i].children[j].children.length;
              for (let k = 0; k < aL; k++) {
                // console.log('区----:', this.provinceList[i].children[j].children)
                obj.areaId = this.provinceList[i].children[j].children[k].id;
                this.provinceList[i].children[j].children[k].checked = statue;
                this.data.push(obj);
              }
            }
          }
        }
      } else {
        // false 删除
        let l = this.data.length;
        for (let i = l; i > 0; i--) {
          if (this.data[i - 1].provinceId === id) {
            this.data.splice(i - 1, 1);
          }
        }

        for (let i = 0; i < pL; i++) {
          if (this.provinceList[i].id == id) {
            this.provinceList[i].checked = statue;
            let cL = this.provinceList[i].children.length;
            for (let j = 0; j < cL; j++) {
              this.provinceList[i].children[j].checked = statue;
              let aL = this.provinceList[i].children[j].children.length;
              for (let k = 0; k < aL; k++) {
                this.provinceList[i].children[j].children[k].checked = statue;
              }
            }
          }
        }
      }
      console.log("data--2--:", this.data);
    },
    // 市复选框状态
    cityChange(pid, id, statue) {
      console.log("市状态：", id, statue);
      let obj = { provinceId: null, cityId: null, areaId: null };
      let pL = this.provinceList.length;
      if (statue) {
        // true 添加
        console.log("市状态添加1：", id, statue);
        for (let i = 0; i < pL; i++) {
          if (this.provinceList[i].id == pid) {
            obj.provinceId = this.provinceList[i].id;
            let cL = this.provinceList[i].children.length;
            for (let j = 0; j < cL; j++) {
              if (this.provinceList[i].children[j].id == id) {
                obj.cityId = this.provinceList[i].children[j].id;
                this.provinceList[i].children[j].checked = statue;
                console.log("-0-0-0-0-0-0：", this.provinceList[i].children[j]);
                // this.provinceList[i].children[j].checked = statue
                let aL = this.provinceList[i].children[j].children.length;
                for (let k = 0; k < aL; k++) {
                  obj.areaId = this.provinceList[i].children[j].children[k].id;
                  this.provinceList[i].children[j].children[k].checked = statue;
                  this.data.push(obj);
                }
              }
              console.log("市----:", this.provinceList[i].children[j].id);
            }
          }
        }
      } else {
        // false 删除
        let l = this.data.length;
        for (let i = l; i > 0; i--) {
          console.log("删除：", this.data);
          if (this.data[i - 1].cityId === id) {
            this.data.splice(i - 1, 1);
          }
        }

        for (let i = 0; i < pL; i++) {
          if (this.provinceList[i].id == pid) {
            // this.provinceList[i].checked = statue
            let cL = this.provinceList[i].children.length;
            for (let j = 0; j < cL; j++) {
              if ((this.provinceList[i].children[j].id = id)) {
                this.provinceList[i].children[j].checked = statue;
                let aL = this.provinceList[i].children[j].children.length;
                for (let k = 0; k < aL; k++) {
                  this.provinceList[i].children[j].children[k].checked = statue;
                }
              }
            }
          }
        }
      }
      console.log("data:", this.data);
    },
    // 区选择框状态改变
    areaChange(pid, id, myid, statue) {
      let obj = { provinceId: null, cityId: null, areaId: null };
      let pL = this.provinceList.length;
      if (statue) {
        // true 添加
        for (let i = 0; i < pL; i++) {
          if (this.provinceList[i].id == pid) {
            obj.provinceId = this.provinceList[i].id;
            let cL = this.provinceList[i].children.length;
            for (let j = 0; j < cL; j++) {
              if (this.provinceList[i].children[j].id == id) {
                obj.cityId = this.provinceList[i].children[j].id;
                let aL = this.provinceList[i].children[j].children.length;
                for (let k = 0; k < aL; k++) {
                  if (this.provinceList[i].children[j].children[k].id == myid) {
                    obj.areaId = this.provinceList[i].children[j].children[k].id;
                    this.provinceList[i].children[j].children[k].checked = statue;
                    this.data.push(obj);
                  }
                }
              }
              console.log("市----:", this.provinceList[i].children[j].id);
            }
          }
        }
      } else {
        let l = this.data.length;
        for (let i = l; i > 0; i--) {
          console.log("删除：", this.data);
          if (this.data[i - 1].cityId === id) {
            this.data.splice(i - 1, 1);
          }
        }

        for (let i = 0; i < pL; i++) {
          if (this.provinceList[i].id == pid) {
            // this.provinceList[i].checked = statue
            let cL = this.provinceList[i].children.length;
            for (let j = 0; j < cL; j++) {
              if ((this.provinceList[i].children[j].id = id)) {
                // this.provinceList[i].children[j].checked = statue
                let aL = this.provinceList[i].children[j].children.length;
                for (let k = 0; k < aL; k++) {
                  if (this.provinceList[i].children[j].children[k].id == myid) {
                    this.provinceList[i].children[j].children[k].checked = statue;
                  }
                }
              }
            }
          }
        }
      }

      console.log("区data:", this.data);
    },
    // 组装数据
    // assemble(data, statue) {
    //     let obj = {
    //         provinceId: null, cityId: null, areaId: null
    //     }
    //     let Arr = []
    //     if(statue){
    //         for(let i = 0; i < data.length; i++){
    //             obj.provinceId = data.id
    //             data.statue = statue
    //             if(data.children){
    //                 obj.children = this.assemble(data.children)
    //
    //             }
    //         }
    //     }
    //   console.log('递归结果：', obj)
    // },
    // 省 弹框隐藏
    cityMouseleave() {
      this.provinceIndex = null;
    },
    // 市 弹框隐藏
    areaMouseleave() {
      this.cityIndex = null;
    },
    // 省指针进入离开
    shengEnter(index) {
      this.provinceIndex = index;
      // if(this.provinceIndex != null){
      //     this.provinceIndex = null
      // } else {
      //
      // }
    },
    shengLeave() {
      // this.provinceIndex = null
    },
    shiEnter(index) {
      this.cityIndex = index;
    },
    shiLeave() {
      this.cityIndex = null;
    },
  },
};
</script>

<style lang="scss" scoped>
i {
  cursor: pointer;
}

.el-dialog {
  margin-top: 500px !important;
}

.cityBox {
  position: fixed;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #cccccc;
  z-index: 999;
  font-size: 20px;

  .cityItem {
    font-size: 16px;
    cursor: pointer;
  }

  .areaBox {
    margin-left: 80px;
    position: fixed;
    padding: 10px;
    background: #ffffff;
    border: 1px solid #cccccc;
    z-index: 999;
  }
}
</style>
