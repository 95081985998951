<template>
  <div>支付成功</div>
</template>

<script>
export default {
  name: '',
}
</script>

<style lang="less" scoped>
</style>
