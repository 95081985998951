var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { attrs: { model: _vm.form } },
    [
      _c(
        "el-form-item",
        { attrs: { label: "是否启用" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.form.isEnable,
              callback: function ($$v) {
                _vm.$set(_vm.form, "isEnable", $$v)
              },
              expression: "form.isEnable",
            },
          }),
        ],
        1
      ),
      _vm.form.isEnable
        ? _c(
            "div",
            [
              _c(
                "el-form-item",
                [
                  _c("el-transfer", {
                    attrs: {
                      titles: ["未选门店", "已选门店"],
                      data: _vm.shopList,
                    },
                    on: { change: _vm.transferChange },
                    model: {
                      value: _vm.form.shopIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "shopIds", $$v)
                      },
                      expression: "form.shopIds",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "微信快递" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingTable,
                          expression: "loadingTable",
                        },
                      ],
                      ref: "multipleTable",
                      staticStyle: { width: "830px" },
                      attrs: {
                        data: _vm.uuData,
                        border: "",
                        "max-height": "320",
                        height: "300",
                        "row-key": "",
                      },
                      on: {
                        "cell-mouse-enter": _vm.cellMouseEnter,
                        "cell-mouse-leave": _vm.cellMouseLeave,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: _vm.tableCellLabel,
                          width: "80",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.hoverRow
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-circle-plus operatePush",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.rowChange(
                                              "push",
                                              scope.$index
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  scope.row.hoverRow
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-remove operateDel",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.rowChange(
                                              "del",
                                              scope.$index
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  !scope.row.hoverRow
                                    ? _c("div", [
                                        _vm._v(_vm._s(scope.$index + 1)),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2480458628
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "是否绑定",
                          align: "center",
                          prop: "type",
                          "min-width": "100",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c("el-switch", {
                                        attrs: {
                                          size: "mini",
                                          "active-value": "bind",
                                          "inactive-value": "unbind",
                                        },
                                        model: {
                                          value: scope.row.type,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "type", $$v)
                                          },
                                          expression: "scope.row.type",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2099614516
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "bizId",
                          align: "center",
                          prop: "bizId",
                          "min-width": "120",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c("el-input", {
                                        attrs: { size: "mini" },
                                        model: {
                                          value: scope.row.bizId,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "bizId", $$v)
                                          },
                                          expression: "scope.row.bizId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3858909384
                        ),
                      }),
                      _c("el-table-column", {
                        key: "6",
                        attrs: {
                          label: "快递公司",
                          align: "center",
                          prop: "deliveryId",
                          "min-width": "120",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择",
                                            filterable: "",
                                            size: "mini",
                                          },
                                          model: {
                                            value: scope.row.deliveryId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "deliveryId",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.deliveryId",
                                          },
                                        },
                                        _vm._l(_vm.uuShopData, function (item) {
                                          return _c("el-option", {
                                            key: item.deliveryId,
                                            attrs: {
                                              value: item.deliveryId,
                                              label: item.deliveryName,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          826451148
                        ),
                      }),
                      _c("el-table-column", {
                        key: "7",
                        attrs: {
                          label: "快递公司客户密码",
                          align: "center",
                          prop: "password",
                          "min-width": "140",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c("el-input", {
                                        attrs: { size: "mini" },
                                        model: {
                                          value: scope.row.password,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "password", $$v)
                                          },
                                          expression: "scope.row.password",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3576621707
                        ),
                      }),
                      _c("el-table-column", {
                        key: "8",
                        attrs: {
                          label: "备注内容",
                          align: "center",
                          prop: "remarkContent",
                          "min-width": "260",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder:
                                            "格式要求：电话：xxxxx 联系人：xxxxx 服务类型：xxxxx 发货地址：xxxx\\n",
                                          size: "mini",
                                        },
                                        model: {
                                          value: scope.row.remarkContent,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "remarkContent",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.remarkContent",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          631414801
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("span", [_vm._v("自定义显示内容：")]),
                  _c("el-input", {
                    staticClass: "inputWidth",
                    attrs: { size: "mini", placeholder: "" },
                    model: {
                      value: _vm.form.customContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "customContent", $$v)
                      },
                      expression: "form.customContent",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "起送金额：" } },
                [
                  _c("span", [_vm._v("订单满")]),
                  _c("el-input-number", {
                    staticStyle: { margin: "0 8px" },
                    attrs: {
                      size: "mini",
                      precision: 2,
                      step: 0.01,
                      min: 0,
                      controls: false,
                    },
                    model: {
                      value: _vm.form.startingAmount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "startingAmount", $$v)
                      },
                      expression: "form.startingAmount",
                    },
                  }),
                  _c("span", [_vm._v("元起送，以实付金额为准")]),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "" } }, [
                _c(
                  "div",
                  {
                    staticClass: "x-start marT10",
                    staticStyle: { "align-items": "center" },
                  },
                  [
                    _c("span", [_vm._v("计费规则：")]),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: 1 },
                        model: {
                          value: _vm.form.calcChargeRule,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "calcChargeRule", $$v)
                          },
                          expression: "form.calcChargeRule",
                        },
                      },
                      [_vm._v(" 按商品累加运费 ")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: 2 },
                        model: {
                          value: _vm.form.calcChargeRule,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "calcChargeRule", $$v)
                          },
                          expression: "form.calcChargeRule",
                        },
                      },
                      [_vm._v(" 按组合运费 ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-form-item",
                { staticClass: "x-start" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }