<template>
  <div class="x-start">
    <el-dialog
      :visible.sync="showGoodsDialog"
      title="商品信息列表"
      :before-close="close"
      v-dialogDrag="true"
      width="70%"
    >
      <div class="x-start" style="width: 100%">
        <CategoryTreeCard
          categoryTreeTitle="商品分类"
          :categoryTreeOptions="treeData"
          :isNoProduct="false"
          :boxHeight="boxHeight"
          :boxWidth="boxWidth"
          @treeNodeId="getTreeNodeId"
        ></CategoryTreeCard>
        <div class="y-start" style="width: calc(100% - 260px)">
          <div class="x-f marB10 marL10">
            <el-input
              v-model="queryParams.query"
              placeholder="请输入商品名称/编号"
            ></el-input>
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-search"
              @click="search"
              >搜索</el-button
            >
            <!--                        <c-button class="whiteColor marL10" test="搜索" icon="el-icon-search" :bgColor="'#188ffe'" @click="search"></c-button>-->
          </div>
          <el-table
            border
            :data="goodsList"
            class="marL10"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              prop=""
              type="selection"
              width="55"
            ></el-table-column>
            <el-table-column
              prop="goodsName"
              align="center"
              label="商品名称"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="goodsNo"
              align="center"
              label="商品编号"
              width="180"
            ></el-table-column>
            <el-table-column
              prop="barcode"
              align="center"
              label="商品条码"
              width="180"
            ></el-table-column>
            <el-table-column
              prop="goodsSpec"
              align="center"
              label="商品规格"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="categoryName"
              align="center"
              label="分类"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="brandName"
              align="center"
              label="品牌名称"
              width="100"
            ></el-table-column>
            <el-table-column></el-table-column>
          </el-table>
        </div>
      </div>
      <span>
        <span slot="footer" class="dialog-footer x-f-end padd15">
          <el-button class="marL10" type="primary" @click="seleceOk"
            >确 定</el-button
          >
          <el-button @click="close">取 消</el-button>
        </span>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import CategoryTreeCard from '@/views/components/categoryTreeCard/index.vue'
import CButton from '@/views/components/c-button/index.vue'
import { categoryList } from '@/api/O2OMall/goods/category'
import { listGoods } from '@/api/goods/goods'
export default {
  name: 'SelectGoodsUp',
  props: {
    showGoodsDialog: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CategoryTreeCard,
    CButton
  },
  data () {
    return {
      boxHeight: '400',
      boxWidth: '260',
      treeData: [], // 商品分类树
      queryParams: {
        status: 0,
        goodsType: 1,
        queryCode: '',
        categoryId: null,
        pageSize: 15,
        pageNum: 1
      },
      tableData: [{}],
      goodsList: [], // 商品列表
      tabSelectData: [] // 列表选择的商品
    }
  },
  created () {
    this.getTreeData()
    this.getGoodsList()
  },
  methods: {
    async getTreeData () {
      await categoryList().then(res => {
        // console.log('分类列表1：', res.data)
        this.treeData = this.recursiveToTree(res.data, 0)
        // console.log('分类列表2：', this.treeData)
      })
    },
    // 列表转换树形列表
    recursiveToTree (data, parentId) {
      const tree = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].parentId == parentId) {
          const node = {
            id: data[i].categoryId,
            label: data[i].categoryName,
            children: this.recursiveToTree(data, data[i].categoryId)
          }
          tree.push(node)
        }
      }
      return tree
    },
    // 获取商品列表
    getGoodsList () {
      listGoods(this.queryParams).then(res => {
        console.log('商品列表：', res)
        this.loading = false
        this.goodsList = res.rows
        this.total = res.total
        this.allNum = this.goodsList.length // 商城列表数量
      })
    },
    //关闭对话框
    close () {
      this.$emit('update:showGoodsDialog', false)
    },
    // 获取
    getTreeNodeId (val) {
      console.log('分类ID：', val)
      this.queryParams.categoryId = val
      this.getGoodsList()
    },
    // 搜索按钮
    search () {
      this.getGoodsList()
    },
    // 列表选择的数据
    handleSelectionChange (val) {
      this.tabSelectData = val
    },
    // 确定按钮
    seleceOk () {
      this.$emit('getGoodS', this.tabSelectData)
    }
  }
}
</script>

<style lang="scss" scoped></style>
