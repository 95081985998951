<template>
  <div class="container" style="overflow-y: scroll">
    <!--        <div class="marB10 bg-Color tableBox x-f-end">-->
    <!--            <c-button test="保存" :bgColor="'#188ffe'" @click="submit"></c-button>-->
    <!--        </div>-->
    <div class="bg-Color tableBox">
      <comCard title="配送管理">
        <template #content>
          <el-form :method="form">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="门店自提" name="shop">
                <PickUpInAstore :shopList="shopList"></PickUpInAstore>
              </el-tab-pane>
              <el-tab-pane label="同城配送" name="identicalCity">
                <TheSameCity :shopList="shopList"></TheSameCity>
              </el-tab-pane>
              <el-tab-pane label="快递配送" name="expressage">
                <ExpressageDistribution :shopList="shopList"></ExpressageDistribution>
              </el-tab-pane>
            </el-tabs>
          </el-form>
        </template>
      </comCard>
    </div>
  </div>
</template>

<script>
import CButton from "@/views/components/c-button/index.vue";
import PickUpInAstore from "@/views/O2OMall/manage/delivery/components/pickUpInAstore.vue";
import TheSameCity from "@/views/O2OMall/manage/delivery/components/TheSameCity.vue";
import ExpressageDistribution from "@/views/O2OMall/manage/delivery/components/expressageDistribution.vue";
import { shopInfoList } from "@/api/O2OMall/manage/shop";

export default {
  name: "index",
  components: {
    CButton,
    PickUpInAstore,
    TheSameCity,
    ExpressageDistribution,
  },
  data() {
    return {
      loading: false,
      activeName: "shop",
      transfer: [],
      switchVal: false,
      form: {
        enableSelfDeliveryTime: false,
        beginOrderDay: "", // 可选择日期(开始时间：单位：天)
        endOrderDay: "", // 可选择日期(结束时间：单位：天)
        enableSelectTime: "", // 是否启用可选择时间(勾选后买家先需要选择具体的时间)
        afterOrderHour: "", // 下单多少小时之后
        timeChoose: "", // 时间段选择(1：是否是自定义时间段; 2: 是否是营业时间段; 3: 是否全天)
        storeIds: [],
        customTime: "", // 自定义时间段
        timeSlots: [],
      },

      checked: false,

      placeAnOrder: false,
      checked1_2: false,
      shopList: [], // 门店列表
    };
  },
  created() {
    this.getShopList();
  },
  methods: {
    // 获取已上架门店列表
    getShopList() {
      this.loading = true;
      shopInfoList({
        pageNum: 1,
        pageSize: 999,
        status: 0,
      }).then((response) => {
        response.rows.forEach((item) => {
          this.shopList.push({
            key: item.shopId,
            label: item.shopName,
          });
        });
        this.loading = false;
        console.log("运费管理-门店列表：", response);
      });
    },
    handleClick(tab, event) {
      console.log("切换页面：", tab.name, event);
      this.activeName = tab.name;
    },

    // 时间段选择
    pickerChange(val) {
      console.log("时间段：", val);
    },
    //表格增加/减少一行方法
    row(name, index) {
      if (name === "push") {
        this.form.tabTimes.splice(index + 1, 0, {});
      } else {
        if (this.form.tabTimes.length <= 1) return;
        this.form.tabTimes.splice(index, 1);
      }
    },
    // 快递配送新增方案
    addBtn() {},
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 10px;
  background: #e9e9e9;

  .btnList {
    padding: 10px;
  }

  .tableBox {
    padding: 10px;
  }

  .inputH {
    .el-input__inner {
      height: 25px;
      line-height: 0;
    }
  }

  .fontColor {
    color: #6e6e6e;
  }

  .box {
  }
}

.marL50 {
  margin-left: 50px;
}
</style>
