var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", staticStyle: { "overflow-y": "scroll" } },
    [
      _c(
        "div",
        { staticClass: "bg-Color tableBox" },
        [
          _c("comCard", {
            attrs: { title: "配送管理" },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c(
                      "el-form",
                      { attrs: { method: _vm.form } },
                      [
                        _c(
                          "el-tabs",
                          {
                            on: { "tab-click": _vm.handleClick },
                            model: {
                              value: _vm.activeName,
                              callback: function ($$v) {
                                _vm.activeName = $$v
                              },
                              expression: "activeName",
                            },
                          },
                          [
                            _c(
                              "el-tab-pane",
                              { attrs: { label: "门店自提", name: "shop" } },
                              [
                                _c("PickUpInAstore", {
                                  attrs: { shopList: _vm.shopList },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-tab-pane",
                              {
                                attrs: {
                                  label: "同城配送",
                                  name: "identicalCity",
                                },
                              },
                              [
                                _c("TheSameCity", {
                                  attrs: { shopList: _vm.shopList },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-tab-pane",
                              {
                                attrs: {
                                  label: "快递配送",
                                  name: "expressage",
                                },
                              },
                              [
                                _c("ExpressageDistribution", {
                                  attrs: { shopList: _vm.shopList },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }