var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "x-start" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "dialogDrag",
              rawName: "v-dialogDrag",
              value: true,
              expression: "true",
            },
          ],
          attrs: {
            visible: _vm.showGoodsDialog,
            title: "商品信息列表",
            "before-close": _vm.close,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showGoodsDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "x-start", staticStyle: { width: "100%" } },
            [
              _c("CategoryTreeCard", {
                attrs: {
                  categoryTreeTitle: "商品分类",
                  categoryTreeOptions: _vm.treeData,
                  isNoProduct: false,
                  boxHeight: _vm.boxHeight,
                  boxWidth: _vm.boxWidth,
                },
                on: { treeNodeId: _vm.getTreeNodeId },
              }),
              _c(
                "div",
                {
                  staticClass: "y-start",
                  staticStyle: { width: "calc(100% - 260px)" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "x-f marB10 marL10" },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品名称/编号" },
                        model: {
                          value: _vm.queryParams.query,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "query", $$v)
                          },
                          expression: "queryParams.query",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "mini",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.search },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      staticClass: "marL10",
                      attrs: { border: "", data: _vm.goodsList },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "", type: "selection", width: "55" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "goodsName",
                          align: "center",
                          label: "商品名称",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "goodsNo",
                          align: "center",
                          label: "商品编号",
                          width: "180",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "barcode",
                          align: "center",
                          label: "商品条码",
                          width: "180",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "goodsSpec",
                          align: "center",
                          label: "商品规格",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "categoryName",
                          align: "center",
                          label: "分类",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "brandName",
                          align: "center",
                          label: "品牌名称",
                          width: "100",
                        },
                      }),
                      _c("el-table-column"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("span", [
            _c(
              "span",
              {
                staticClass: "dialog-footer x-f-end padd15",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    attrs: { type: "primary" },
                    on: { click: _vm.seleceOk },
                  },
                  [_vm._v("确 定")]
                ),
                _c("el-button", { on: { click: _vm.close } }, [
                  _vm._v("取 消"),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }