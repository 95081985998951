var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "积分兑换商品", name: "goods" } },
            [
              _c("div", { staticStyle: { "margin-left": "15px" } }, [
                _c("div", { staticClass: "btnBox x-b" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "marT10",
                          attrs: {
                            type: "primary",
                            icon: "el-icon-plus",
                            size: "mini",
                          },
                          on: { click: _vm.handleAdd },
                        },
                        [_vm._v("新增 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            icon: "el-icon-delete",
                            size: "mini",
                            disabled: _vm.multiple,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete("goods")
                            },
                          },
                        },
                        [_vm._v("删除 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "info",
                            plain: "",
                            icon: "el-icon-refresh",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.refresh($event, "goods")
                            },
                          },
                        },
                        [_vm._v("刷新 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c("el-input", {
                        staticClass: "marB20",
                        attrs: { placeholder: "请输入商品名称", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          },
                        },
                        model: {
                          value: _vm.queryParams.goodsName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "goodsName", $$v)
                          },
                          expression: "queryParams.goodsName",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "marB20",
                          attrs: {
                            type: "primary",
                            icon: "el-icon-search",
                            size: "mini",
                          },
                          on: { click: _vm.handleQuery },
                        },
                        [_vm._v("查询 ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "tableBox" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: { data: _vm.tableData, border: "", height: "500" },
                      on: {
                        "row-click": _vm.handleRowClick,
                        "selection-change": _vm.handleSelectionChange,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          type: "index",
                          width: "80",
                          label: "序号",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品编码",
                          align: "center",
                          prop: "goodsNo",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      target: "_blank",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleShopDetail(
                                          scope.row.scoreGoodsId
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(scope.row.goodsNo) + " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品名称",
                          align: "center",
                          prop: "goodsName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单位",
                          align: "center",
                          prop: "unitName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "等价积分",
                          align: "center",
                          prop: "equalScore",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "加购金额",
                          align: "center",
                          prop: "addBuyPrice",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "总发放数量",
                          align: "center",
                          prop: "goodsEmitQty",
                          "show-overflow-tooltip": true,
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "每人限兑换数量",
                          align: "center",
                          prop: "goodsEveryLimitQty",
                          "show-overflow-tooltip": true,
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "已兑换数量",
                          align: "center",
                          prop: "goodsSumScoreQty",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "生效时间",
                          align: "center",
                          prop: "startTime",
                          width: "155",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "失效时间",
                          align: "center",
                          prop: "endTime",
                          width: "155",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "修改人",
                          align: "center",
                          prop: "updateBy",
                          "show-overflow-tooltip": true,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "修改时间",
                          align: "center",
                          prop: "updateTime",
                          "show-overflow-tooltip": "",
                          width: "155",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建人",
                          align: "center",
                          prop: "createBy",
                          "show-overflow-tooltip": true,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建时间",
                          align: "center",
                          prop: "createTime",
                          "show-overflow-tooltip": "",
                          width: "155",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "适用渠道",
                          align: "center",
                          prop: "allowOnline",
                          "show-overflow-tooltip": true,
                          width: "155",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total > 0",
                      },
                    ],
                    attrs: {
                      total: _vm.total,
                      page: _vm.queryParams.pageNum,
                      limit: _vm.queryParams.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.queryParams, "pageNum", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.queryParams, "pageSize", $event)
                      },
                      pagination: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "积分兑换优惠券", name: "coupon" } },
            [
              _c("div", { staticStyle: { "margin-left": "15px" } }, [
                _c("div", { staticClass: "btnBox x-b" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "marT10",
                          attrs: {
                            type: "primary",
                            icon: "el-icon-plus",
                            size: "mini",
                          },
                          on: { click: _vm.handleAddCoupon },
                        },
                        [_vm._v("新增 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            icon: "el-icon-delete",
                            size: "mini",
                            disabled: _vm.multiple,
                          },
                          on: { click: _vm.handleDeleteCoupon },
                        },
                        [_vm._v("删除 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "info",
                            icon: "el-icon-refresh",
                            size: "mini",
                            plain: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.refresh($event, _vm.name)
                            },
                          },
                        },
                        [_vm._v("刷新 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c("el-input", {
                        staticClass: "marB20",
                        attrs: {
                          placeholder: "请输入优惠券名称",
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleCouponQuery($event)
                          },
                        },
                        model: {
                          value: _vm.queryParams.couponCaseName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "couponCaseName", $$v)
                          },
                          expression: "queryParams.couponCaseName",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "marB20",
                          attrs: {
                            type: "primary",
                            icon: "el-icon-search",
                            size: "mini",
                          },
                          on: { click: _vm.handleCouponQuery },
                        },
                        [_vm._v("查询 ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "tableBox" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: { data: _vm.tableData, border: "", height: "500" },
                      on: {
                        "selection-change": _vm.handleSelectionChangeCoupon,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          type: "index",
                          width: "80",
                          label: "序号",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "优惠券编号",
                          align: "center",
                          prop: "equalScore",
                          width: "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      target: "_blank",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleCouponDetail(
                                          scope.row.scoreCouponId
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(scope.row.equalScore) + " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "优惠券名称",
                          align: "center",
                          prop: "couponCaseName",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "等价积分",
                          align: "center",
                          prop: "equalScore",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "加购金额",
                          align: "center",
                          prop: "addBuyPrice",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "总发放数量",
                          align: "center",
                          prop: "couponEmitQty",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "每人限兑数量",
                          align: "center",
                          prop: "couponEveryLimitQty",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "已兑换数量",
                          align: "center",
                          prop: "couponSumScoreQty",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "生效时间",
                          align: "center",
                          prop: "startTime",
                          width: "155",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "失效时间",
                          align: "center",
                          prop: "endTime",
                          width: "155",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "修改人",
                          align: "center",
                          prop: "updateBy",
                          "show-overflow-tooltip": true,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "修改时间",
                          align: "center",
                          prop: "updateTime",
                          "show-overflow-tooltip": "",
                          width: "155",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建人",
                          align: "center",
                          prop: "createBy",
                          "show-overflow-tooltip": true,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建时间",
                          align: "center",
                          prop: "createTime",
                          "show-overflow-tooltip": "",
                          width: "155",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "适用渠道",
                          align: "center",
                          prop: "allowOnline",
                          "show-overflow-tooltip": true,
                          width: "155",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total > 0",
                      },
                    ],
                    attrs: {
                      total: _vm.total,
                      page: _vm.queryParams.pageNum,
                      limit: _vm.queryParams.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.queryParams, "pageNum", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.queryParams, "pageSize", $event)
                      },
                      pagination: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "dialogDrag",
              rawName: "v-dialogDrag",
              value: true,
              expression: "true",
            },
          ],
          attrs: {
            title: _vm.changeShopTitle,
            visible: _vm.openChangeShop,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openChangeShop = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "goodsForm",
              attrs: {
                rules: _vm.rulesGoods,
                model: _vm.goodsForm,
                "label-width": "150px",
              },
            },
            [
              _c("el-form-item", {
                attrs: { label: "商品名称", prop: "goodsName" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-input",
                          {
                            staticClass: "inputWidthM",
                            attrs: {
                              placeholder: "请选择商品名称",
                              readonly: "",
                            },
                            model: {
                              value: _vm.goodsForm.goodsName,
                              callback: function ($$v) {
                                _vm.$set(_vm.goodsForm, "goodsName", $$v)
                              },
                              expression: "goodsForm.goodsName",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-more more marT10",
                              attrs: {
                                slot: "suffix",
                                "padding-bottom": "10px",
                              },
                              on: { click: _vm.selectShopInfo },
                              slot: "suffix",
                            }),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "el-form-item",
                { attrs: { label: "基本单位", prop: "unitId" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "inputWidthM",
                          attrs: { placeholder: "请选择基本单位" },
                          model: {
                            value: _vm.goodsForm.unitId,
                            callback: function ($$v) {
                              _vm.$set(_vm.goodsForm, "unitId", $$v)
                            },
                            expression: "goodsForm.unitId",
                          },
                        },
                        _vm._l(_vm.goodUnits, function (item) {
                          return _c("el-option", {
                            key: item.unitId,
                            attrs: { label: item.unitName, value: item.unitId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "适用渠道", prop: "isAllowOfflineShop" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.goodsForm.isAllowOfflineShop,
                        callback: function ($$v) {
                          _vm.$set(_vm.goodsForm, "isAllowOfflineShop", $$v)
                        },
                        expression: "goodsForm.isAllowOfflineShop",
                      },
                    },
                    [_vm._v("线下门店 ")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.goodsForm.isAllowOnlineShop,
                        callback: function ($$v) {
                          _vm.$set(_vm.goodsForm, "isAllowOnlineShop", $$v)
                        },
                        expression: "goodsForm.isAllowOnlineShop",
                      },
                    },
                    [_vm._v("线上商城 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "等价积分", prop: "equalScore" } },
                [
                  _c("el-input", {
                    staticClass: "inputWidthM",
                    attrs: { placeholder: "请输入等价积分", type: "number" },
                    on: {
                      input: function ($event) {
                        return _vm.validateNumber("equalScore", _vm.goodsForm)
                      },
                    },
                    model: {
                      value: _vm.goodsForm.equalScore,
                      callback: function ($$v) {
                        _vm.$set(_vm.goodsForm, "equalScore", $$v)
                      },
                      expression: "goodsForm.equalScore",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "加购金额", prop: "addBuyPrice" } },
                [
                  _c("el-input", {
                    staticClass: "inputWidthM",
                    attrs: {
                      placeholder: "请输入加购金额",
                      type: "number",
                      oninput: "if(value<=0)value=''",
                    },
                    model: {
                      value: _vm.goodsForm.addBuyPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.goodsForm, "addBuyPrice", $$v)
                      },
                      expression: "goodsForm.addBuyPrice",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "总发行兑换量", prop: "goodsEmitQty" } },
                [
                  _c("el-input", {
                    staticClass: "inputWidthM",
                    attrs: {
                      placeholder: "请输入总发行兑换量",
                      type: "number",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.validateNumber("goodsEmitQty", _vm.goodsForm)
                      },
                    },
                    model: {
                      value: _vm.goodsForm.goodsEmitQty,
                      callback: function ($$v) {
                        _vm.$set(_vm.goodsForm, "goodsEmitQty", $$v)
                      },
                      expression: "goodsForm.goodsEmitQty",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "每人限兑数量", prop: "goodsEveryLimitQty" },
                },
                [
                  _c("el-input", {
                    staticClass: "inputWidthM",
                    attrs: {
                      placeholder: "请输入每人限兑数量",
                      type: "number",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.validateNumber(
                          "goodsEveryLimitQty",
                          _vm.goodsForm
                        )
                      },
                    },
                    model: {
                      value: _vm.goodsForm.goodsEveryLimitQty,
                      callback: function ($$v) {
                        _vm.$set(_vm.goodsForm, "goodsEveryLimitQty", $$v)
                      },
                      expression: "goodsForm.goodsEveryLimitQty",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "兑换商品有效时间", prop: "datetime" } },
                [
                  _c(
                    "div",
                    { staticClass: "custom-date-picker" },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "default-time": ["00:00:00", "23:59:59"],
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "picker-options": _vm.forbiddenTime,
                        },
                        on: { input: _vm.inputDatetime },
                        model: {
                          value: _vm.goodsForm.datetime,
                          callback: function ($$v) {
                            _vm.$set(_vm.goodsForm, "datetime", $$v)
                          },
                          expression: "goodsForm.datetime",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "备注长度介于 1 和 30 字符之间",
                      maxlength: "30",
                      autosize: { minRows: 2, maxRows: 4 },
                    },
                    model: {
                      value: _vm.goodsForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.goodsForm, "remark", $$v)
                      },
                      expression: "goodsForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitGoodsForm },
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.AddBill } },
                [_vm._v("保存并新增")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "dialogDrag",
              rawName: "v-dialogDrag",
              value: true,
              expression: "true",
            },
          ],
          attrs: {
            title: _vm.changeCouponTitle,
            visible: _vm.openChangeCoupon,
            width: "500px",
            "before-close": _vm.cancelCouponForm,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openChangeCoupon = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "couponForm",
              attrs: {
                model: _vm.couponForm,
                rules: _vm.rulesCoupon,
                "label-width": "150px",
              },
            },
            [
              _c("el-form-item", {
                attrs: { label: "优惠券名称", prop: "couponCaseName" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-input",
                          {
                            staticClass: "inputWidthM",
                            attrs: {
                              placeholder: "请输入优惠券名称",
                              maxlength: "11",
                            },
                            model: {
                              value: _vm.couponForm.couponCaseName,
                              callback: function ($$v) {
                                _vm.$set(_vm.couponForm, "couponCaseName", $$v)
                              },
                              expression: "couponForm.couponCaseName",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-more more marT10",
                              attrs: {
                                slot: "suffix",
                                "padding-bottom": "10px",
                              },
                              on: { click: _vm.selectCouponInfo },
                              slot: "suffix",
                            }),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "el-form-item",
                { attrs: { label: "等价积分", prop: "equalScore" } },
                [
                  _c("el-input", {
                    staticClass: "inputWidthM",
                    attrs: { placeholder: "请输入等价积分", type: "number" },
                    on: {
                      input: function ($event) {
                        return _vm.validateNumber("equalScore", _vm.couponForm)
                      },
                    },
                    model: {
                      value: _vm.couponForm.equalScore,
                      callback: function ($$v) {
                        _vm.$set(_vm.couponForm, "equalScore", $$v)
                      },
                      expression: "couponForm.equalScore",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "加购金额", prop: "addBuyPrice" } },
                [
                  _c("el-input", {
                    staticClass: "inputWidthM",
                    attrs: {
                      placeholder: "请输入加购金额",
                      type: "number",
                      oninput: "if(value<=0)value=''",
                    },
                    model: {
                      value: _vm.couponForm.addBuyPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.couponForm, "addBuyPrice", $$v)
                      },
                      expression: "couponForm.addBuyPrice",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "适用渠道", prop: "isAllowOfflineShop" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.couponForm.isAllowOfflineShop,
                        callback: function ($$v) {
                          _vm.$set(_vm.couponForm, "isAllowOfflineShop", $$v)
                        },
                        expression: "couponForm.isAllowOfflineShop",
                      },
                    },
                    [_vm._v("线下门店 ")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.couponForm.isAllowOnlineShop,
                        callback: function ($$v) {
                          _vm.$set(_vm.couponForm, "isAllowOnlineShop", $$v)
                        },
                        expression: "couponForm.isAllowOnlineShop",
                      },
                    },
                    [_vm._v("线上商城 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "总发行兑换量", prop: "couponEmitQty" } },
                [
                  _c("el-input", {
                    staticClass: "inputWidthM",
                    attrs: {
                      placeholder: "请输入总发行兑换量",
                      type: "number",
                      maxlength: "11",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.validateNumber(
                          "couponEmitQty",
                          _vm.couponForm
                        )
                      },
                    },
                    model: {
                      value: _vm.couponForm.couponEmitQty,
                      callback: function ($$v) {
                        _vm.$set(_vm.couponForm, "couponEmitQty", $$v)
                      },
                      expression: "couponForm.couponEmitQty",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "每人限兑数量", prop: "couponEveryLimitQty" },
                },
                [
                  _c("el-input", {
                    staticClass: "inputWidthM",
                    attrs: {
                      placeholder: "请输入每人限兑数量",
                      type: "number",
                      oninput: "if(value<0)value=0",
                      maxlength: "11",
                    },
                    model: {
                      value: _vm.couponForm.couponEveryLimitQty,
                      callback: function ($$v) {
                        _vm.$set(_vm.couponForm, "couponEveryLimitQty", $$v)
                      },
                      expression: "couponForm.couponEveryLimitQty",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "兑换优惠券有效时间", prop: "datetime" } },
                [
                  _c(
                    "div",
                    { staticClass: "custom-date-picker" },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "default-time": ["00:00:00", "23:59:59"],
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "picker-options": _vm.forbiddenTime,
                        },
                        on: { input: _vm.inputDatetime },
                        model: {
                          value: _vm.couponForm.datetime,
                          callback: function ($$v) {
                            _vm.$set(_vm.couponForm, "datetime", $$v)
                          },
                          expression: "couponForm.datetime",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "备注长度介于 1 和 30 字符之间",
                      maxlength: "30",
                      autosize: { minRows: 2, maxRows: 4 },
                    },
                    model: {
                      value: _vm.goodsForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.goodsForm, "remark", $$v)
                      },
                      expression: "goodsForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitCouponForm },
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitCouponFormAdd },
                },
                [_vm._v("保存并新增 ")]
              ),
              _c("el-button", { on: { click: _vm.cancelCouponForm } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("selectCoupon", {
        attrs: {
          OpenCoupon: _vm.openCoupon,
          isRadio: true,
          isSuingEditorIsRadio: true,
        },
        on: {
          "update:OpenCoupon": function ($event) {
            _vm.openCoupon = $event
          },
          "update:open-coupon": function ($event) {
            _vm.openCoupon = $event
          },
          getCoupons: function ($event) {
            return _vm.getPitchData($event, "coupon")
          },
        },
      }),
      _c("selectGoodsUp", {
        ref: "selectGoods",
        attrs: {
          OpenGoods: _vm.OpenGoods,
          queryCode: _vm.queryCode,
          isSelectGoodsRadio: true,
        },
        on: {
          "update:OpenGoods": function ($event) {
            _vm.OpenGoods = $event
          },
          "update:open-goods": function ($event) {
            _vm.OpenGoods = $event
          },
          getGoodsItem: _vm.getGoodsItem,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }