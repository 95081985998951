<template>
    <div class="container">
      <div class="x-bc bg-Color padR15 padL15 marB10">
        <h3>{{ `${type !== "edit" ? "新增" : "编辑"}运费模板` }}</h3>
        <el-button type="primary" size="mini" @click="submit">保存</el-button>
      </div>
      <div class="bg-Color Box">
        <el-form
          :model="form"
          label-width="100px"
          :rules="rules"
          ref="ruleForm"
          :show-message="false"
        >
          <comCard title="基本信息设置">
            <template #content>
              <el-form-item label="模板名称" prop="templateName">
                <el-input
                  class="inputWidth"
                  placeholder="请输入模板名称"
                  v-model="form.templateName"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="起送金额" prop="startingAmount">
                <el-input
                  class="inputWidth"
                  type="number"
                  v-model="form.startingAmount"
                ></el-input>
                <span class="marL15">元</span>
              </el-form-item> -->
              <el-form-item label="计费方式" prop="isFreeShipping">
                <el-radio-group v-model="form.billingType" @change="billingTypeChange">
                  <el-radio :label="1">按件数</el-radio>
                  <el-radio :label="2">按重量</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-table
                :data="form.areaItems"
                min-height="105px"
                style="width: 100%"
                border
              >
                <el-table-column
                  prop="rangeText"
                  label="可配送区域"
                  min-width="600"
                ></el-table-column>
                <el-table-column
                  prop="name"
                  align="center"
                  :label="form.billingType == 1 ? '首件（件）' : '首重（Kg）'"
                  min-width="100"
                >
                  <template slot-scope="scope" v-if="showForm">
                    <el-input-number
                      style="width: 100%"
                      v-if="form.billingType == 1"
                      v-model="scope.row.firstQty"
                      :precision="0"
                      :step="1"
                      :min="1"
                      :controls="false"
                    />
                    <el-input-number
                      style="width: 100%"
                      v-else
                      v-model="scope.row.firstWeight"
                      :precision="3"
                      :step="0.001"
                      :min="0.001"
                      :controls="false"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  align="center"
                  label="运费（元）"
                  min-width="100"
                >
                  <template slot-scope="scope" v-if="showForm">
                    <el-input-number
                      style="width: 100%"
                      v-if="form.billingType == 1"
                      v-model="scope.row.firstAmount"
                      :precision="2"
                      :step="0.01"
                      :min="0"
                      :controls="false"
                    />
                    <el-input-number
                      v-else
                      style="width: 100%"
                      v-model="scope.row.firstWeightAmount"
                      :precision="2"
                      :step="0.01"
                      :min="0"
                      :controls="false"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  align="center"
                  :label="form.billingType == 1 ? '续件（件）' : '续重（Kg）'"
                  min-width="100"
                >
                  <template slot-scope="scope" v-if="showForm">
                    <el-input-number
                      style="width: 100%"
                      v-if="form.billingType == 1"
                      v-model="scope.row.renewalQty"
                      :precision="0"
                      :step="1"
                      :min="0"
                      :controls="false"
                    />
                    <el-input-number
                      v-else
                      style="width: 100%"
                      v-model="scope.row.renewalWeight"
                      :precision="3"
                      :step="0.001"
                      :min="0"
                      :controls="false"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  align="center"
                  label="续费（元）"
                  min-width="100"
                >
                  <template slot-scope="scope" v-if="showForm">
                    <el-input-number
                      style="width: 100%"
                      v-if="form.billingType == 1"
                      v-model="scope.row.renewalAmountByNumber"
                      :precision="2"
                      :step="0.01"
                      :min="0"
                      :controls="false"
                    />
                    <el-input-number
                      v-else
                      style="width: 100%"
                      v-model="scope.row.renewalAmountByWeight"
                      :precision="2"
                      :step="0.01"
                      :min="0"
                      :controls="false"
                    />
                  </template>
                </el-table-column>
                <el-table-column prop="rangeText" label="操作" width="60" align="center">
                  <template slot-scope="scope">
                    <i
                      @click="delTableData(scope.$index)"
                      class="el-icon-delete"
                      style="color: red; font-size: 18px; cursor: pointer"
                    ></i>
                  </template>
                </el-table-column>
              </el-table>
              <div class="y-start marT20">
                <el-button type="primary" size="mini" @click="openDialog"
                  >指定可配送区域和运费</el-button
                >
              </div>
            </template>
          </comCard>
        </el-form>
      </div>
      <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
    </div>
  </template>
  
  <script>
  import Dialog from "@/components/Dialog";
  import CButton from "@/views/components/c-button/index.vue";
  import {
    templateGetDetail,
    templateSave,
    templateUpdate,
  } from "@/api/O2OMall/manage/freight";
  import { getAllRegion } from "@/api/goods/region"; // 全部省市区 树接口
  import DistrictSelector from "@/views/O2OMall/manage/components/districtSelector.vue";
  import { uniqBy, cloneDeep } from "lodash";
  export default {
    name: "index",
    components: {
      CButton,
      DistrictSelector,
      Dialog,
    },
    data() {
      return {
        dialogOptions: {
          show: false,
          type: "O2OFreightTemp",
          width: "auto",
          title: "配送区域",
        },
        area: [],
        isRegion: false, // 打开区域选择弹框
        form: {
          templateName: "", // 模板名称
          billingType: 1, // 是否包邮
          areaItems: [],
        },
        rules: {
          templateName: [{ required: true, message: "请输入模板名称", trigger: "blur" }],
          billingType: [{ required: true, message: "请选择是否包邮", trigger: "change" }],
        },
        showForm: true,
        provinceList: [], // 省市区
        provinceId: null,
        cityId: null,
        type: "",
      };
    },
    async created() {
      try {
        const provinceListRes = await getAllRegion();
        this.provinceList = provinceListRes.data;
      } catch (error) {}
      if (this.$route.query.templateId) {
        this.type = "edit";
        const res = await templateGetDetail({ templateId: this.$route.query.templateId });
        res.data.areaItems = res.data.areaItems.map((x) => ({
          ...x,
          rangeText: this.getRangeText(x.areaDetailItems),
        }));
        this.form = res.data;
      }
    },
    methods: {
      delTableData(i) {
        this.form.areaItems.splice(i, 1);
      },
      billingTypeChange() {
        this.showForm = false;
        this.$nextTick(() => {
          this.showForm = true;
        });
      },
      getRangeText(row) {
        const ps = uniqBy(row.map((item) => item.provinceId));
        let allText = [];
        ps.forEach((provinceId) => {
          let Labels = [];
          let inAs = []; // 全选市
          // 选中的市
          const cs = uniqBy(
            row.filter((x) => x.provinceId === provinceId).map((x) => x.cityId)
          );
          const psItem = this.provinceList.find((x) => x.id === provinceId);
          if (psItem.children?.length) {
            cs.forEach((cityId) => {
              // 选中的区
              const as = uniqBy(
                row.filter((x) => x.cityId === cityId).map((x) => x.areaId)
              );
              const csItem = psItem.children.find((x) => x.id === cityId);
              if (csItem.children.length === as.length) {
                // 全部的区都选择
                inAs.push(csItem.label);
              } else {
                Labels = [
                  ...Labels,
                  ...csItem.children.filter((x) => as.includes(x.id)).map((x) => x.label),
                ];
              }
            });
          }
          if (inAs.length === (psItem.children?.length || 0)) {
            // 选中了所有市
            allText = [...allText, psItem.label];
          } else {
            allText = [...allText, ...inAs, ...Labels];
          }
        });
        // 芙蓉区 株洲市 广东省 北海市
        return allText.join(",");
      },
      handleEvent(e, row) {
        switch (e) {
          case "dialogChange":
            this.form.areaItems.push({
              areaDetailItems: row.formData.list,
              rangeText: this.getRangeText(row.formData.list),
  
              firstAmount: 0,
              firstQty: 1,
              firstWeight: 0.001,
              firstWeightAmount: 0,
              renewalAmountByNumber: 0,
              renewalAmountByWeight: 0,
              renewalQty: 0,
              renewalWeight: 0,
            });
            break;
          default:
            break;
        }
      },
      // openSelectAddres
      async openDialog() {
        // 获取全部的已选择省市区 id
        // 过滤已选择的
        let data = cloneDeep(this.provinceList);
        const allAs = uniqBy(
          this.form.areaItems.reduce((acc, cur, i) => [...acc, ...cur.areaDetailItems], []),
          (item) => `${item.provinceId}.${item.cityId}.${item.areaId}`
        ).map((x) => x.areaId);
        function deleteNodesById(tree, ids) {
          for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (ids.includes(node.id)) {
              tree.splice(i, 1);
              i--;
            } else if (node.children?.length) {
              deleteNodesById(node.children, ids);
            }
          }
        }
        function removeEmptyChildren(tree, deepIndex = 1) {
          for (let i = 0; i < tree.length; i++) {
            if (!tree[i].children?.length && deepIndex < 3) {
              tree.splice(i, 1);
              i--;
            } else if (tree[i].children) {
              removeEmptyChildren(tree[i].children, deepIndex + 1);
            }
          }
        }
        deleteNodesById(data, allAs);
        removeEmptyChildren(data); // 删除市
        removeEmptyChildren(data); // 删除省
        if (!data?.length) return this.$message.error("无可用城市可选！");
        this.dialogOptions = {
          ...this.dialogOptions,
          show: true,
          data,
          formData: { list: [] },
        };
      },
      // 保存按钮
      async submit() {
        try {
          await this.$refs.ruleForm.validate();
        } catch (error) {
          this.$message.error(Object.values(error)[0][0].message);
          return;
        }
        if (!this.form.areaItems?.length) {
          return this.$message.error("请指定可配送区域");
        }
        try {
          if (this.form.templateId) {
            await templateUpdate(this.form);
            this.$message.success("运费模板编辑成功");
          } else {
            await templateSave(this.form);
            this.$message.success("运费模板添加成功");
          }
        } catch (error) {}
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .container {
    padding: 10px;
    background: #e9e9e9;
  
    .btnList {
      padding: 10px;
    }
  
    .redColor {
      color: red;
    }
  
    .Box {
      padding: 10px;
  
      .inputW160 {
        width: 160px;
      }
  
      .el-dropdown-link {
        cursor: pointer;
        //color: #409EFF;
        margin-right: 20px;
      }
  
      .el-icon-arrow-down {
        font-size: 12px;
      }
  
      .marR40 {
        margin-left: 50px;
      }
  
      .disabledStyle {
        cursor: no-drop;
      }
    }
  }
  </style>
  