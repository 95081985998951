var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "x-bc bg-Color padR15 padL15 marB10" },
        [
          _c("h3", [
            _vm._v(
              _vm._s((_vm.type !== "edit" ? "新增" : "编辑") + "运费模板")
            ),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.submit },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bg-Color Box" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                "label-width": "100px",
                rules: _vm.rules,
                "show-message": false,
              },
            },
            [
              _c("comCard", {
                attrs: { title: "基本信息设置" },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function () {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "模板名称", prop: "templateName" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: { placeholder: "请输入模板名称" },
                              model: {
                                value: _vm.form.templateName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "templateName", $$v)
                                },
                                expression: "form.templateName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "计费方式",
                              prop: "isFreeShipping",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: { change: _vm.billingTypeChange },
                                model: {
                                  value: _vm.form.billingType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "billingType", $$v)
                                  },
                                  expression: "form.billingType",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("按件数"),
                                ]),
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("按重量"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.form.areaItems,
                              "min-height": "105px",
                              border: "",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "rangeText",
                                label: "可配送区域",
                                "min-width": "600",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                align: "center",
                                label:
                                  _vm.form.billingType == 1
                                    ? "首件（件）"
                                    : "首重（Kg）",
                                "min-width": "100",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return _vm.showForm
                                        ? [
                                            _vm.form.billingType == 1
                                              ? _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    precision: 0,
                                                    step: 1,
                                                    min: 1,
                                                    controls: false,
                                                  },
                                                  model: {
                                                    value: scope.row.firstQty,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "firstQty",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.firstQty",
                                                  },
                                                })
                                              : _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    precision: 3,
                                                    step: 0.001,
                                                    min: 0.001,
                                                    controls: false,
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.firstWeight,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "firstWeight",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.firstWeight",
                                                  },
                                                }),
                                          ]
                                        : undefined
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "address",
                                align: "center",
                                label: "运费（元）",
                                "min-width": "100",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return _vm.showForm
                                        ? [
                                            _vm.form.billingType == 1
                                              ? _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    precision: 2,
                                                    step: 0.01,
                                                    min: 0,
                                                    controls: false,
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.firstAmount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "firstAmount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.firstAmount",
                                                  },
                                                })
                                              : _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    precision: 2,
                                                    step: 0.01,
                                                    min: 0,
                                                    controls: false,
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .firstWeightAmount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "firstWeightAmount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.firstWeightAmount",
                                                  },
                                                }),
                                          ]
                                        : undefined
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "address",
                                align: "center",
                                label:
                                  _vm.form.billingType == 1
                                    ? "续件（件）"
                                    : "续重（Kg）",
                                "min-width": "100",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return _vm.showForm
                                        ? [
                                            _vm.form.billingType == 1
                                              ? _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    precision: 0,
                                                    step: 1,
                                                    min: 0,
                                                    controls: false,
                                                  },
                                                  model: {
                                                    value: scope.row.renewalQty,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "renewalQty",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.renewalQty",
                                                  },
                                                })
                                              : _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    precision: 3,
                                                    step: 0.001,
                                                    min: 0,
                                                    controls: false,
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.renewalWeight,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "renewalWeight",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.renewalWeight",
                                                  },
                                                }),
                                          ]
                                        : undefined
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "address",
                                align: "center",
                                label: "续费（元）",
                                "min-width": "100",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return _vm.showForm
                                        ? [
                                            _vm.form.billingType == 1
                                              ? _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    precision: 2,
                                                    step: 0.01,
                                                    min: 0,
                                                    controls: false,
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .renewalAmountByNumber,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "renewalAmountByNumber",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.renewalAmountByNumber",
                                                  },
                                                })
                                              : _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    precision: 2,
                                                    step: 0.01,
                                                    min: 0,
                                                    controls: false,
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .renewalAmountByWeight,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "renewalAmountByWeight",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.renewalAmountByWeight",
                                                  },
                                                }),
                                          ]
                                        : undefined
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "rangeText",
                                label: "操作",
                                width: "60",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("i", {
                                        staticClass: "el-icon-delete",
                                        staticStyle: {
                                          color: "red",
                                          "font-size": "18px",
                                          cursor: "pointer",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delTableData(
                                              scope.$index
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "y-start marT20" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: { click: _vm.openDialog },
                              },
                              [_vm._v("指定可配送区域和运费")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("Dialog", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }